<template>
 <!-- <div v-if="!api.includes('https://telecom0580-clients.bluerocktel.net')"> -->
 <PageHeading
  :has-meta-data="false"
  :has-action-button="false"
  :has-search="true"
  :title="$t('invoices')"
  @open="this.openForm = !this.openForm"
  @update:search="$emit('update:search', $event)"
 />

 <div v-if="tbody?.length > 0">
  <div class="flex flex-col">
   <div class="-my-0 sm:-mx-6 lg:-mx-0">
    <div class="align-middle inline-block min-w-full sm:px-6 lg:px-10">
     <InvoiceBody
      :search="search"
      :thead="thead"
      :tbody="filteredInvoices()"
      :data="data"
      @sort="(this.currentSort = $event), sort($event)"
      @action-detail="(...args) => openDetail(...args)"
     />
    </div>
   </div>
  </div>
 </div>
 <div v-else>
  <EmptyState
   :title="$t('emptyStateTitle', { title: $t('invoice') })"
   :subtitle="$t('emptyStateSubtitle', { title: $t('invoice') })"
  />
 </div>
 <!-- </div> -->
</template>

<script>
import axios from "axios";

import { VueCookieNext } from "vue-cookie-next";
import PageHeading from "../components/PageHeading.vue";
import InvoiceBody from "../components/InvoiceBody.vue";
import EmptyState from "../components/EmptyState.vue";

const account =
 localStorage.getItem("account") === ""
  ? this.$store.state.user.activeAccount
  : localStorage.getItem("account");

const api = VueCookieNext.getCookie("API");

export default {
 name: "Invoices",
 props: ["colors", "search"],
 components: {
  InvoiceBody,
  PageHeading,
  EmptyState,
 },

 data() {
  return {
   account,
   api,
   currentSort: "id",
   currentSortDir: "desc",
   searchMonth: "",
   searchYear: "",
   searchNumb: "",
   searchTotalWithTax: "",
   searchTotalWithoutTax: "",
   thead: [
    { name: "invoiceTable.invoiceNumb", sort: "number", isSort: false },
    { name: "invoiceTable.date", sort: "date", isSort: false },
    {
     name: "invoiceTable.totalWithoutTax",
     sort: "totalWithoutTax",
     isSort: false,
    },
    {
     name: "invoiceTable.totalWithTax",
     sort: "totalWithTax",
     isSort: false,
    },
   ],
   tbody: [],
  };
 },
 methods: {
  async getInvoices() {
   this.$emit("setLoading", true);
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/invoices?customerAccount=${this.account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    this.tbody = res.data;
   } catch (error) {
    this.errorHandling(error);
   }
   this.$emit("setLoading", false);
  },
  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  sortedInvoices() {
   return this.tbody.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (toBeSorted === "totalWithoutTax" || toBeSorted === "totalWithTax") {
     if (parseFloat(a[toBeSorted]) < parseFloat(b[toBeSorted])) return -1 * modifier;
     if (parseFloat(a[toBeSorted]) > parseFloat(b[toBeSorted])) return 1 * modifier;
    } else {
     if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
     if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
    }
    return 0;
   });
  },
  numberIsEqual(numb) {
   return String(numb).includes(String(this.search));
  },

  filteredInvoices() {
   return this.sortedInvoices().filter((res) => {
    if (this.search) {
     const searchLower = this.search.toLowerCase();

     const totalWithoutTaxMatch = res.totalWithoutTax.toLowerCase().includes(searchLower);
     const totalWithTaxMatch = res.totalWithTax.toLowerCase().includes(searchLower);
     const numberMatch = res.number.toLowerCase().includes(searchLower);
     const dateMatch = this.$d(res.date, "shortText")
      .toLowerCase()
      .includes(searchLower.toLowerCase());

     return numberMatch || dateMatch || totalWithoutTaxMatch || totalWithTaxMatch;
    }
    return res;
   });
  },
  getYear(date) {
   const dateCreated = new Date(date);
   const formatedDate = dateCreated.getFullYear();

   return String(formatedDate);
  },
  getMonth(date) {
   const dateCreated = new Date(date);
   const formatedDate = ("0" + (dateCreated.getMonth() + 1)).slice(-2);
   return String(formatedDate);
  },
  async openDetail(...args) {
   const id = args[0];
   const account = args[1];
   const documentType = args[2];
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/invoices/document?customerAccount=${account}&invoiceId=${id}&type=${documentType}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
     }
    );
    console.log(res);
    const file = new Blob([await res.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
   } catch (err) {
    console.error(err);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", this.errorDataResponse);
   }
  },
 },
 mounted() {
  this.getInvoices();
 },
};
</script>

<style>
.invoice_account_numb {
 text-align: center;
 padding: 10px;
 margin-right: 56px; /** same width of the sidebar */
}
</style>
