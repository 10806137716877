<template>
 <div class="mt-8 flow-root">
  <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
   <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
     <table class="min-w-full divide-y divide-gray-300">
      <thead class="bg-gray-50">
       <tr>
        <th
         v-for="(header, index) in thead"
         :key="index"
         scope="col"
         :class="{ 'pl-4 pr-3 sm:pl-6': index === 0, 'px-3': index !== 0 }"
         class="capitalize py-3.5 text-left"
        >
         <button
          @click="setSorting(header.sort, header.name)"
          class="text-sm font-semibold text-gray-900 group inline-flex"
         >
          {{ $t(header.name) }}

          <span
           v-if="header.isSort"
           class="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
          >
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5"
           >
            <path
             fill-rule="evenodd"
             d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
             clip-rule="evenodd"
            />
           </svg>
          </span>

          <span
           v-else
           class="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
          >
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5"
           >
            <path
             fill-rule="evenodd"
             d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
             clip-rule="evenodd"
            />
           </svg>
          </span>
         </button>
        </th>
        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6 text-left">
         <span class="sr-only">Download document</span>
        </th>
       </tr>
      </thead>

      <tbody class="divide-y divide-gray-200 bg-white">
       <tr v-for="data in pagination().querySet" :key="data.id" class="hover:bg-gray-50">
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
         {{ data.number }}
        </td>
        <td class="capitalize whitespace-nowrap px-3 py-4 text-sm text-gray-500">
         {{ $d(data.date, "shortText") }}
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
         {{ $n(parseInt(data.totalWithoutTax), "currency") }}
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
         {{ $n(parseInt(data.totalWithTax), "currency") }}
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 space-x-2">
         <button
          type="button"
          @click="$emit('actionDetail', data.id, this.account, 'invoice')"
          :title="$t('downloadInvoice')"
          :class="[
           'hover:ring-inset hover:ring-1 hover:bg-gray-100 hover:text-gray-500 text-gray-700 relative -ml-px inline-flex items-center rounded-md bg-white px-2 py-1 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10',
          ]"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 20 20"
           fill="currentColor"
           class="w-5 h-5"
          >
           <path
            d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z"
           />
           <path
            d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z"
           />
          </svg>
         </button>
         <button
          v-if="data.csFile"
          type="button"
          @click="$emit('actionDetail', data.id, this.account, 'consumptions')"
          :class="[
           'hover:ring-inset hover:ring-1 hover:bg-gray-100 text-gray-700 relative -ml-px inline-flex items-center rounded-md bg-white px-2 py-1 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10',
          ]"
          :title="$t('downloadConsumption', { type: 'PDF' })"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 20 20"
           fill="currentColor"
           class="w-5 h-5"
          >
           <path
            fill-rule="evenodd"
            d="M4.5 2A1.5 1.5 0 003 3.5v13A1.5 1.5 0 004.5 18h11a1.5 1.5 0 001.5-1.5V7.621a1.5 1.5 0 00-.44-1.06l-4.12-4.122A1.5 1.5 0 0011.378 2H4.5zm2.25 8.5a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5zm0 3a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z"
            clip-rule="evenodd"
           />
          </svg>
         </button>
         <button
          v-if="data.csCsv"
          type="button"
          @click="$emit('actionDetail', data.id, this.account, 'consumptions_csv')"
          :class="[
           'hover:ring-inset hover:ring-1 hover:bg-gray-100 text-gray-700 relative -ml-px inline-flex items-center rounded-md bg-white px-2 py-1 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10',
          ]"
          :title="$t('downloadConsumption', { type: 'CSV' })"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 20 20"
           fill="currentColor"
           class="w-5 h-5"
          >
           <path
            fill-rule="evenodd"
            d="M3 3.5A1.5 1.5 0 014.5 2h6.879a1.5 1.5 0 011.06.44l4.122 4.12A1.5 1.5 0 0117 7.622V16.5a1.5 1.5 0 01-1.5 1.5h-11A1.5 1.5 0 013 16.5v-13zM13.25 9a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5a.75.75 0 01.75-.75zm-6.5 4a.75.75 0 01.75.75v.5a.75.75 0 01-1.5 0v-.5a.75.75 0 01.75-.75zm4-1.25a.75.75 0 00-1.5 0v2.5a.75.75 0 001.5 0v-2.5z"
            clip-rule="evenodd"
           />
          </svg>

          <!--  <svg
           xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 20 20"
           fill="currentColor"
           class="w-5 h-5"
          >
           <path
            fill-rule="evenodd"
            d="M3 3.5A1.5 1.5 0 014.5 2h6.879a1.5 1.5 0 011.06.44l4.122 4.12A1.5 1.5 0 0117 7.622V16.5a1.5 1.5 0 01-1.5 1.5h-11A1.5 1.5 0 013 16.5v-13zM13.25 9a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5a.75.75 0 01.75-.75zm-6.5 4a.75.75 0 01.75.75v.5a.75.75 0 01-1.5 0v-.5a.75.75 0 01.75-.75zm4-1.25a.75.75 0 00-1.5 0v2.5a.75.75 0 001.5 0v-2.5z"
            clip-rule="evenodd"
           />
          </svg> -->
         </button>
        </td>
       </tr>
      </tbody>
     </table>
     <PaginationComponent
      :backend_pagination="false"
      :current_page="1"
      :select_rows="selectRows"
      first_page_url="test"
      :from="this.rows * this.page - this.rows + 1"
      :last_page="10"
      last_page_url="data.last_page_url"
      links="data.links"
      next_page_url="data.next_page_url"
      :per_page="5"
      :to="getPageLength()"
      :total="this.tbody.length"
      @action="$emit('action', $event)"
      @set-rows="setRows($event)"
      @previousPage="previousPage()"
      @nextPage="nextPage()"
     />
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import PaginationComponent from "./PaginationComponent.vue";

const account =
 localStorage.getItem("account") === ""
  ? this.$store.state.user.activeAccount
  : localStorage.getItem("account");

export default {
 name: "InvoiceBody",
 props: {
  data: {
   type: Array,
   required: true,
  },
  thead: {
   type: Array,
   required: true,
  },
  tbody: {
   type: Array,
   required: true,
  },
 },
 emits: ["actionDetail", "sort"],
 components: {
  PaginationComponent,
  Switch,
  SwitchGroup,
  SwitchLabel,
 },
 data() {
  return {
   account,
   enabled: false,
   page: 1,
   rows: 10,
   selectedRows: null,
   selectRows: [5, 10, 15, 20, 25, 30],
  };
 },
 methods: {
  setSorting(sort, header) {
   this.$emit("sort", sort);
   this.activeSorting = header;

   // Set the selected header's isSort value to the opposite of its current value
   const selectedHeader = this.thead.find((header) => header.sort === sort);
   selectedHeader.isSort = !selectedHeader.isSort;

   // Loop through the other headers and set their isSort value to false
   this.thead.forEach((header) => {
    if (header.sort !== sort) {
     header.isSort = false;
    }
   });
  },
  getPageLength() {
   if (this.page === this.pagination().pages) {
    return this.tbody.length;
   } else {
    return this.rows * this.page;
   }
  },
  setRows(row) {
   this.selectedRows = row;
  },
  pagination() {
   if (this.selectedRows) {
    this.rows = this.selectedRows;
   }
   let trimStart = (this.page - 1) * parseInt(this.rows);
   let trimEnd = trimStart + parseInt(this.rows);

   let trimmedData = this.tbody.slice(trimStart, trimEnd);

   let pages = Math.ceil(this.tbody.length / this.rows);

   return {
    querySet: trimmedData,
    pages: pages,
   };
  },
  nextPage() {
   if (this.page < this.pagination().pages) {
    this.page = parseInt(this.page) + 1;
   } else {
    this.page = this.page;
   }
  },
  previousPage() {
   if (this.page > 1) {
    this.page = parseInt(this.page) - 1;
   } else {
    this.page = this.page;
   }
  },
 },
 mounted() {},
 computed: {
  ...mapGetters(["language"]),
 },
};
</script>

<style>
.invoice_table {
 overflow-x: hidden;
 overflow-y: hidden;
}
</style>
